<template>
	<main class="vcc-main vcc-main__kb">
		<h1 class="visually-hidden">База знаний</h1>
		<h2 class="heading heading__hr hr__bold">Виды деятельности</h2>
		<section class="materials-main">
			<section class="kb--aside">
				<nav class="kb--nav">
					<ul class="kb-nav--list">
						<li><router-link :to="{name: 'Materials'}" class="link__menu">Материалы</router-link></li>
						<li><router-link :to="{name: 'Rlas'}" class="link__menu">Нормативно-правовые акты</router-link></li>
						<li><router-link :to="{name: 'BusinessTypes'}" class="link__menu">Виды деятельности</router-link></li>
					</ul>
				</nav>
				<small-email-form type="business_types"/>
			</section>
			<section class="list-wth-filters">
				<section class="search-section">
					<form @submit.prevent="">
						<div class="input-wrapper">
							<label for="webinars-search" class="label__input">Поиск по названию</label>
							<input type="search" name="webinars-search" id="webinars-search" class="input" placeholder="Начните вводить название" v-model="search_req" list="webinars-types">
						</div>
					</form>
					<section class="sorting-section">
						<hr class="hr__blue">
						<div class="sorting--info">
							<dl class="dl__theme sorting-section--dl">
								<dt>Соответствует запросу</dt>
								<dd><b>{{filteredItems.length}}</b></dd>
							</dl>
							<dl class="dl__theme sorting-section--dl">
								<dt>Всего в базе</dt>
								<dd><b>{{getBusinessTypes.length}}</b></dd>
							</dl>
							<dl class="dl__theme dl--filter sorting-section--dl">
								<dt><b>Сортировать</b></dt>
								<dd>
									<div class="filter-group">
										<input type="radio" name="order" id="asc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="false">
										<label for="asc" class="sorting-by">с начала</label>
									</div>
									<div class="filter-group">
										<input type="radio" name="order" id="desc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="true">
										<label for="desc" class="sorting-by">с конца</label>
									</div>
									<div :class="['filter-group', {'chrome-date': isChrome }]">
										<input type="date" name="sort-date" id="filter-date" :class="['sort-date', 'date-visually-hidden', 'visually-hidden', {'date-not-empty': filter.date != null}]" v-model="filter.date">
										<label for="filter-date" class="sorting-by">
											по дате
											<svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .66a.57.57 0 0 1-.21.45L4.36 3.87a.57.57 0 0 1-.73 0L.21 1.01A.57.57 0 0 1 .94.13L4 2.7 7.06.22A.57.57 0 0 1 8 .66Z" fill="#9C9D9D"/></svg>
										</label>
									</div>
								</dd>
							</dl>
						</div>
					</section>
				</section>
				<ul class="kb--list">
					<li v-for="material, key in filteredItems" :key="'business-type-'+key">
						<vcc-kb-card :card-info="material" card-type="business_type" />
					</li>
				</ul>
			</section>
		</section>
	</main>
</template>

<script>
import SmallEmailForm from '@/components/forms/SmallEmailForm.vue'
import KbCard from '@/components/cards/KbCard.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	data() {
		return {
			items_per_page: 9,
			page_number: this.page || 1,
			search_req: null,
			filter: {
				date : null,
				sort_by: true,
				filter_by: []
			},
		}
	},
	components: {
		'vcc-kb-card': KbCard,
		'small-email-form': SmallEmailForm
	},
	computed: {
		...mapGetters(['getBusinessTypes']),
		filteredItems() {
			const materials = this.getBusinessTypes
			const searched_materials = this.search_req != null ? materials.filter(item => item.type.toLowerCase().includes(this.search_req.toLowerCase())) : materials
			return searched_materials.sort((a, b) => {
				if(this.filter.sort_by) {
					return b.materials.length - a.materials.length
				} else {
					return a.materials.length - b.materials.length
				}
			})
			.filter((item) => {
				if(this.filter.filter_by.length > 0) {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.isEventToday(item.created, this.filter.date) ? this.filter.filter_by.includes(item.type) : false
					} else {
						return this.filter.filter_by.includes(item.type)
					}
				} else {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.isEventToday(item.created, this.filter.date) ? item : false
					} else {
						return item
					}
				}

			})
		}
	},
	methods: mapActions(['fetchAllBusinessTypes']),
	mounted() {
		this.fetchAllBusinessTypes()
	}

}
</script>

<style>

</style>
